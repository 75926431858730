// import { scroller } from "react-scroll";

// const scrollToSection = () => {
//   scroller.scrollTo("#footer", {
//     duration: 800,
//     delay: 0,
//     smooth: "easeInOutQuart",
//   });
// };

const features = [
  {
    image: "images/icons/database.png",
    label: "Advanced Membership database with unique Member ID",
  },
  {
    image: "images/icons/events.png",
    label:
      "Events creation & management with Mobile Payment & Digital Event Pass",
  },
  {
    image: "images/icons/consent.png",
    label: "Digital user consent for Events & Services to minimize liability ",
  },
  {
    image: "images/icons/people.png",
    label: "Household management and creation",
  },
  {
    label: "Mobile contactless Event check-in",
    image: "images/icons/checkin.png",
  },
  {
    image: "images/icons/notification-bell.svg",
    label: "Mobile push notifications to all users or a specific group",
  },
  {
    image: "images/icons/donation.png",
    label:
      "Mobile Donations (Apple Pay, Google Pay & Credit Card) with on-demand tax receipt",
  },
  {
    image: "images/icons/liturgy.png",
    label:
      "Liturgy Booking with customizable Attendance policies (e.g. COVID-19)",
    width: 20,
  },
  {
    image: "images/icons/dynamic-score.svg",
    label:
      "ChurchPlan dynamic Score to help users achieve their spiritual goals",
    width: 30,
  },

  {
    image: "images/icons/heart.png",
    label: "Donations management, automatic tax receipts generation",
  },
  {
    label: "Church Services creation & management, Attendance tracking",
    image: "images/icons/services.png",
  },
  {
    label:
      "Web-based Admin Portal to manage & control all System and Mobile features",
  },
  {
    image: "images/icons/confession.png",
    label: "Confession scheduling synced to Priest calendar and availability",
  },
  {
    image: "images/icons/venu.png",
    label: "Venu/Room booking system",
  },
  {
    image: "images/icons/analytics.png",
    label: "Advanced Analytics for better decision-making",
  },
  {
    image: "images/icons/outreach.png",
    label:
      "Outreach scheduling synced to Priest calendar and availability, with confidential encrypted notes",
  },
  {
    label: "Mobile Shopping",
    image: "images/icons/shopping.png",
  },

  {
    label: "Advanced Reports & Dashboards",
    image: "images/icons/report.svg",
  },
];

function MainBanner() {
  return (
    <section id='home' className='hero-area text-black py-4'>
      {/* Overview section */}
      <div
        id='overview'
        className='py-5'
        style={{
          backgroundImage: "url('/images/showcase_cropped.png')",
          backgroundSize: "contain",
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
          margin: "auto",
        }}
      >
        <div className='container py-3'>
          <div className='row align-items-center'>
            <div className='col-lg-5 col-md-12 col-12'>
              <div className='hero-content'>
                <h1
                  className='wow fadeInLeft my-3 d-inline-flex'
                  data-wow-delay='.4s'
                >
                  Church <span className='text-primary'>Plan</span>
                </h1>

                <h5>
                  The most comprehensive cloud-based church management system
                </h5>
                <p className='wow fadeInLeft text-bold' data-wow-delay='.6s'>
                  Church<span className='text-primary'>Plan</span> is a 3-in-1
                  system consisting of a CRM, ERP and EDM modules in a single
                  database. it provides native exchange of information without
                  the need of creating and configuring digital interfaces
                  between various tools.
                </p>
                <p className='wow fadeInLeft text-bold' data-wow-delay='.6s'>
                  Church<span className='text-primary'>Plan</span> is an
                  integrated solution providing everything the church management
                  and congregation need in one place. It replaces a variety of
                  tools yout church might be using today to accomplish various
                  tasks.
                </p>
                <p className='wow fadeInLeft text-bold' data-wow-delay='.6s'>
                  The free Mobile App (iOS & Android) connects memebers with
                  their church for everything the need. While the web-based
                  Admin Portal provides all the tools church management needs to
                  efficently manage the church business.
                </p>
                <div className='wow fadeInLeft' data-wow-delay='.8s'>
                  <a
                    style={{
                      borderRadius: 20,
                    }}
                    href='https://admin.churchplan.org'
                    target='_blank'
                    rel='noreferrer'
                    className='btn btn-primary my-2 py-2 px-3'
                  >
                    Client Login
                  </a>
                </div>
              </div>
            </div>

            <div className='col-lg-7 col-md-12 col-12'></div>
          </div>
        </div>
      </div>

      {/* Features section */}
      <div id='features'>
        <div className='container pb-5'>
          <div className='row align-items-center'>
            <div className='row'>
              {features.map((x, idx) => {
                return (
                  <div
                    key={idx}
                    className='p-2 col-s-12 col-md-6 col-lg-4 text-left d-flex align-items-center'
                  >
                    <img
                      style={{ width: x.width || 25, minHeight: 20 }}
                      className='mx-4'
                      src={x.image || "images/icons/screen_cog.png"}
                      alt=''
                    />
                    {x.label}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainBanner;
